import React from 'react';
import { Link } from 'react-router-dom';

import Logo from "../assets/images/Header/Logo.svg"

import Reservations from "../assets/images/Products/Reservations.png"
import Menu from "../assets/images/Products/Menu.png"
import Online from "../assets/images/Products/Online.png"
import Order from "../assets/images/Products/Order.png"

export default function NewProducts(props) {
  const { ProductsData } = props;

  return (
    <section className="all-products">
      <div className="all-products-title">
        <h1>Unsere Services</h1>
        <p>
          Unsere cloudbasierte Software bietet eine umfassende Lösung für Unternehmen. Sie umfasst unter anderem die Bereiche Marketing, Tischreservierungen und Kundenmanagement.
          <br />
        </p>
      </div>
      <div class="main">

          <div class="module module-left">
              <div class="left">
                  <div class="title">
                      <img class="icon" src={Logo}/>
                      <div class="title-text">
                          Reservation
                      </div>
                  </div>

                  <img class="illustration" src={Reservations}/>

              </div>

              <div class="right">
                  <div class="what-is">Booste deine Reservierungen – mit Feedboost Reservations!</div>
                  <div>
                      Erleichtere dir das <b>Reservieren und Verwalten</b> von Tischen in deinem Restaurant mit <b>Feedboost Reservations</b>! Unsere innovative Software bietet dir eine benutzerfreundliche Plattform, auf der Kunden ganz einfach über das <b>Tische reservieren</b> können. Mit Feedboost Reservations behältst du stets den Überblick über deine Reservierungen, kannst <b>Kapazitäten</b> optimieren und den Gästen ein reibungsloses Reservierungserlebnis bieten. Steigere deine <b>Kundenzufriedenheit</b> und maximiere deine <b>Auslastung</b> mit Feedboost Reservations – die ideale Lösung für effizientes Tischmanagement in der Gastronomie.
                  </div>

                  <button class="learn-more">Erfahre mehr!</button>
              </div>
          </div>

          <div class="module module-right">
              <div class="left">
                  <div class="title">
                      <img class="icon" src={Logo}/>
                      <div class="title-text">
                          Online
                      </div>
                  </div>

                  <img class="illustration" src={Online}/>
              </div>

              <div class="right">
                  <div class="what-is">Bring dein Restaurant online – mit Feedboost Online!</div>
                  <div>
                      Mache dein Restaurant zum digitalen Hotspot mit Feedboost Online! Unser umfassendes Softwarepaket ermöglicht es Restaurants, einen professionellen Webauftritt zu erhalten und online präsent zu sein. Mit Feedboost Online kannst du eine ansprechende Website erstellen, deine Speisekarte veröffentlichen, Reservierungen entgegennehmen und deine Gäste mit aktuellen Angeboten und Events auf dem Laufenden halten. Zeige der Welt, was dein Restaurant zu bieten hat und ziehe neue Kunden an. Steigere deine Sichtbarkeit und Umsätze mit Feedboost Online – die perfekte Lösung für einen erfolgreichen Online-Auftritt deines Restaurants.
                  </div>
                  <button class="learn-more">Erfahre mehr!</button>
              </div>
          </div>

          <div class="module module-left">
              <div class="left">
                  <div class="title">
                      <img class="icon" src={Logo}/>
                      <div class="title-text">
                          Menu
                      </div>
                  </div>

                  <img class="illustration" src={Menu}/>
              </div>

              <div class="right">
                  <div class="what-is">Eine mächtige Speisekarte für Ihr Lokal</div>
                  <div>
                      Text hier
                  </div>
                  <button class="learn-more">Erfahre mehr!</button>
              </div>
          </div>

          <div class="module module-right">
              <div class="left">
                  <div class="title">
                      <img class="icon" src={Logo}/>
                      <div class="title-text">
                          Order
                      </div>
                  </div>

                  <img class="illustration" src={Order}/>
              </div>

              <div class="right">
                  <div class="what-is">Online Bestellungen über das Web</div>
                  <div>
                    Text hier
                  </div>
                  <button class="learn-more">Erfahre mehr!</button>
              </div>
          </div>
          <div class="module module-left">
              <div class="left">
                  <div class="title">
                      <img class="icon" src={Logo}/>
                      <div class="title-text">
                          Newsletter
                      </div>
                  </div>

                  <img class="illustration" src={Reservations}/>

              </div>

              <div class="right">
                  <div class="what-is">Slogan</div>
                  <div>
                    Description
                  </div>

                  <button class="learn-more">Erfahre mehr!</button>
              </div>
          </div>
      </div>
    </section>
  );
}
