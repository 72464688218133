import React, { useState } from 'react';

import GalleryList from './GalleryList';
import MediaModal from './MediaModal';
import { gallery } from '../json/gallery.json';

export default function Gallery() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSource, setImageSource] = useState();
  const [imageDescription, setImageDescription] = useState();

  return (
    <section className="gallery">
      <div className="gallery-title">
        <h1>Preise</h1>
        <p>
          Unsere cloudbasierte Software bietet konkurrenzfähige Preise, um Unternehmen jeder Größe eine kosteneffektive Lösung zu bieten.
        </p>
      </div>
      <div className="gallery-pricing">
        <table>
          <thead>
            <tr>
              <td />
              <th>
                <div>
                  <p className="Feedboost">Feedboost</p>
                  <p className="Name Imbiss">Imbiss</p>
                </div>
              </th>
              <th>
                <div>
                  <p className="Feedboost">Feedboost</p>
                  <p className="Name Restaurant">Restaurant</p>
                </div>
              </th>
              <th>
                <div>
                  <p className="Feedboost">Feedboost</p>
                  <p className="Name Complete">Ultimate</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="Parameter">Preis</th>
              <td>
                <div>79,00€ / Monat</div>
              </td>
              <td>
                <div>109,00€ / Monat</div>
              </td>
              <td>
                <div>189,00€ / Monat</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">Benutzer</th>
              <td>
                <div>10</div>
              </td>
              <td>
                <div>10</div>
              </td>
              <td>
                <div>Unbegrenzt</div>
              </td>
            </tr>
            <tr>
              <th className="Category">Module</th>
              <td>
                <div />
              </td>
              <td>
                <div />
              </td>
              <td>
                <div />
              </td>
            </tr>
            <tr>
              <th className="Parameter">Reservierungsverwaltung</th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">Gästeverwaltung</th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Tischverwaltung
                <br />
                mit automatischer Zuweisung
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">Bestellungen</th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">Speisekarte</th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">Insights</th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">Analyse</th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                KI-Basierte Bewertung und
                <br />
                Schwachstellenfindung
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Terminverwaltung
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Schichtverwaltung
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Mitarbeiterverwaltung
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Category">
                Webauftritt
              </th>
              <td>
                <div />
              </td>
              <td>
                <div />
              </td>
              <td>
                <div />
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Domäne und Hosting
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Webseite
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Online Speisekarte
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Online Bestellung
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Online Tisch-Reservierung
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Category">
                Newsletter
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Newsletter Baukasten
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th className="Parameter">
                Newsletter Verschickung
              </th>
              <td>
                <div />
              </td>
              <td>
                <div>✔</div>
              </td>
              <td>
                <div>✔</div>
              </td>
            </tr>
            <tr>
              <th> </th>
              <td className="Selection">
                <div>
                  <button type="button" className="Select Imbiss-Select">
                    Auswählen
                  </button>
                </div>
              </td>
              <td className="Selection">
                <div>
                  <button type="button" className="Select Restaurant-Select">
                    Auswählen
                  </button>
                </div>
              </td>
              <td className="Selection">
                <div>
                  <button type="button" className="Select Complete-Select">
                    Auswählen
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}
