import React, { useRef, useEffect } from 'react';
import VanillaTilt from 'vanilla-tilt';

import BenefitDrone from '../assets/images/Benefit/mockup_newsletter.png';

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

export default function Benefit() {
  return (
    <section className="benefit-container">
      <div className="benefit">
        <div className="benefit-description">
          <h1>Warum FeedBoost?</h1>
          <p>
            Unsere cloudbasierte Software bietet eine umfassende Lösung für Unternehmen.
            Sie umfasst unter anderem die Bereiche Marketing, Tischreservierungen und Kundenmanagement.
          </p>
        </div>
      </div>
      <Tilt className="benefit-blank">
        <img src={BenefitDrone} alt="Benefit" width="668px" />
      </Tilt>
    </section>
  );
}
