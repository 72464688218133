import React from 'react';
import { Link } from 'react-router-dom';

import Discount from '../assets/images/Header/discount.svg';
import HeroImage from '../assets/images/Header/mockup_feedboost.png';

export default function Hero() {
  return (
    <section className="hero">
      <div className="hero-description">
        <h1 className="hero-description-heading">
          FeedBoost
          <br />
          Digitalisierung für die Gastronomie
        </h1>
        <p className="hero-description-paragraph">
          Wir bieten eine Lösung für Restaurants und Gastronomiebetriebe jeder Größe, um Geschäftsprozesse zu optimieren und den Betrieb zu verbessern.
        </p>
        <div className="hero-description-button">
          <Link to="/">Mehr Informationen</Link>
        </div>
      </div>
      <div className="hero-image">
        <img src={HeroImage} alt="Hero" />
      </div>
    </section>
  );
}
