import React, { useEffect } from 'react';

import Header from '../components/Header';
import ProductConfigurator from '../components/ProductConfigurator';
import Footer from '../components/Footer';

export default function Configurator() {
  useEffect(() => {
    document.title = 'Gallery | DroneShopID';
  });

  return (
    <>
      <Header />
      <ProductConfigurator />
      <Footer />
    </>
  );
}
