import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

import HeaderMobileModal from './HeaderMobileModal';
import BrandLogo from '../assets/images/Header/Logo.svg';
import { ActionCreators } from '../redux/actions';
import useSignOut from '../services/signOut';
import decrypt from '../utils/decrypt';

export default function Navbar(props) {
  const { reRender } = props;

  const State = useSelector((state) => state);
  const cartState = State.Cart.Cart;
  const userDataState = State.DroneShop.DroneShop ? decrypt(State.DroneShop.DroneShop) : State.DroneShop;
  const dispatch = useDispatch();

  const [mobileModalHeader, setMobileModalHeader] = useState(false);

  return (
    <header className="navigation">
      <Link to="/" className="nav-logo">
        <img src={BrandLogo} alt="Drone Shop ID" />
        <p>FeedBoost</p>
      </Link>
      <div className="nav-link">
        <Link to="/" className="nav-link-list">Home</Link>
        <Link to="/products" className="nav-link-list">Services</Link>
        <Link to="/gallery" className="nav-link-list">Preise</Link>
        <Link to="/configurator" className="nav-link-list">Konfigurator</Link>
      </div>
      <div className="nav-button">
        <Link to="/signin" className="nav-button-signin">Anmelden</Link>
        <Link to="/signup" className="nav-button-register">Registrieren</Link>
      </div>
      <div className="mobile-modal-header-toggle">
        <button type="button" onClick={() => setMobileModalHeader(true)} className="mobile-modal-header-button">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      <HeaderMobileModal isOpen={mobileModalHeader} closeModal={() => setMobileModalHeader(false)} isLogin={false} />
    </header>
  );
}
