import React, { useState } from 'react';

import ProductCard from './ProductCard';

export default class ProductConfigurator extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modules: ["Reservations", "Online", "Menu", "Order", "Newsletter"],
      selectedModules: ["Reservations", "Online"]
    }
  }

  selectedModule(module) {
    if (this.state.selectedModules.includes(module))
      this.state.selectedModules.splice(this.state.selectedModules.indexOf(module), 1);
    else
      this.state.selectedModules.push(module);

    this.forceUpdate()
  }
/*TODO Fade on keywords*/
/*
<div>
          Frage 1
          <h2 className='quesion'>Möchten Sie Reservierungen verwalten und Kunden die Möglichkeit geben, Reservierungen online zu erstellen?</h2>
          <div className='quesion-answers'>
            <button className='yes'>Auf jeden Fall!</button>
            <button className='no'>Lieber nicht</button>
          </div>
        </div>
        <div>
          Frage 2
          <h2 className='quesion'>Möchten Sie Reservierungen verwalten und Kunden die Möglichkeit geben, Reservierungen online zu erstellen?</h2>
          <div className='quesion-answers'>
            <button className='yes'>Auf jeden Fall!</button>
            <button className='no'>Lieber nicht</button>
          </div>
        </div>
*/
  render() {
    return (
      <section className="configurator">
        <div className="configurator-title" >
          <h1>Konfigurieren Sie Ihr Feedboost</h1>
          <p>
            Wählen Sie aus den untenstehenden Modulen aus und konfigurieren Sie Ihre 
          </p>
        </div>
        <div className="configurator-content">

          <h1>Module</h1>
          <div className='all-modules-list'>
            {
              this.state.modules.map(module => <ProductCard inSelectionArea={false} selected={this.state.selectedModules.includes(module)} name={module} select={() => this.selectedModule(module)}/>)
            }
          </div>


          <h1>Ihre Auswahl</h1>
          


          <div className='selected-modules'>
            <div className='selected-module-list'>
              {
                this.state.selectedModules.map(module => <ProductCard inSelectionArea={true} select={() => this.selectedModule(module)} name={module}/>)
              }
            </div>
            
            <div className='buy-or-test'>
              <div>Sie sind mit Ihrer Auswahl zufrieden?</div>
              <button className='buy'>Sofort bestellen</button>
              oder 
              <button className='test'>Kostenlos testen</button>
              
            </div>
            
          </div>

        </div>
      </section>
    );
  }
}
