
import Logo from "../assets/images/Header/Logo.svg"

import Reservations from "../assets/images/Products/Reservations.png"
import Menu from "../assets/images/Products/Menu.png"
import Online from "../assets/images/Products/Online.png"
import Order from "../assets/images/Products/Order.png"

export default function ProductCard(props) {

    let imgSrc;
    switch (props.name) {
        case "Reservations":
            imgSrc = Reservations
            break;
        case "Online":
            imgSrc = Online
            break;
        case "Menu":
            imgSrc = Menu
            break;
        case "Order":
            imgSrc = Order
            break;
        case "Newsletter":
            imgSrc = Reservations
            break;
    }

    let desc;
    switch (props.name) {
        case "Reservations":
            desc = "Die Reservierungen und Belegungen auf einem Blick sowie Tische online reservieren."
            break;
        case "Online":
            desc = "Ein starker und representativer Webauftritt, der Ihre Online-Präsenz vebessert und den Kunden viele Möglichkeiten bietet."
            break;
        case "Menu":
            desc = "Eine von Ihnen leicht anpassbare Speisekarte, die Ihren Kunden online präsentiert werden kann."
            break;
        case "Order":
            desc = "Eine Möglichkeit für Kunden online bei Ihnen zu bestellen, ohne, dass Provisionen gezahlt werden müssen."
            break;
        case "Newsletter":
            desc = "Halten Sie Kontakt mit Ihren Kunden und informieren Sie diese über Aktionen."
            break;
    }

    let price;
    switch (props.name) {
        case "Reservations":
            price = 20
            break;
        case "Online":
            price = 15
            break;
        case "Menu":
            price = 20
            break;
        case "Order":
            price = 30
            break;
        case "Newsletter":
            price = 15
            break;
    }

    return (
        <div className="product-card">
            <div className="product-card-title">
                <img className={"product-card-feedboost-logo " + (!props.inSelectionArea && props.selected ? "product-card-image-gray" : "")} src={Logo}/>
                {props.name}
            </div>
            <img className={"product-card-image " + (!props.inSelectionArea && props.selected ? "product-card-image-gray" : "")} src={imgSrc}/>
            <div className="product-card-description">
                {desc}
            </div>
            <div className="product-card-price">
                <b>{price}</b> € / Monat
            </div>
            {props.inSelectionArea ? <></> : <a className="product-card-learn-more" href="reservations">Erfahre mehr!</a>}
            {!props.inSelectionArea && !props.selected ? <button className="product-card-button product-card-button-add" onClick={() => props.select()}>Hinzufügen</button> : <></>}
            {props.inSelectionArea ? <button className="product-card-button product-card-button-remove" onClick={() => props.select()}>Entfernen</button> : <></>}
        </div>
    );

}